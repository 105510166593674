import React from 'react'
import { Link } from 'gatsby'
import {
  usePopupState,
  bindHover,
  bindMenu,
} from 'material-ui-popup-state/hooks'
import Menu from 'material-ui-popup-state/HoverMenu'
import { MenuItem } from '@material-ui/core'

import { Item } from './styles'

const MenuPopupState = props => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'menu' })
  const { items } = props

  return (
    <>
      <Item {...bindHover(popupState)}>{props.text}</Item>
      <Menu
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {items.map(item => (
          <MenuItem onClick={popupState.close} key={item.name}>
            {item.type !== 'external' ?
              <Link to={item.link} style={{ width: '100%' }}>{item.name}</Link>
              :
              <a href={item.link} target="_blank">{item.name}</a>
            }
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default MenuPopupState
