import { Box, Button } from '@material-ui/core'
import styled from 'styled-components'

export const Div = styled(Box)`
  background: #001f3b;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 50px;
  height: 70px;
  .contact {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  form {
    display: flex;
    align-items: center;
  }
  a {
    margin: 0 5px;
    color: #fff;
  }
  button,
  a {
    text-transform: initial;
    font-size: 15px;
  }
  .menu-button {
    display: none;
  }
  .menu-wrapper {
    display: flex;
    align-items: center;
  }
  @media (max-width: 870px) {
    .menu-button {
      display: initial;
    }
    .menu-wrapper {
      display: none;
    }
    .contact {
      display: none;
    }
    form {
      position: relative;
      left: 15%;
    }
    input {
      width: 100px;
    }
  }
  @media (max-width: 968px) {
    .action-button {
      display: none;
    }
  }
`
