import React from 'react'
import { Link } from 'gatsby'

import { Grid, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../../images/whatsappicon.png'
import logoABED from '../../images/logo-abed.png'

import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  YouTube as YoutubeIcon,
  WhatsApp as WhatsAppIcon,
} from '@material-ui/icons'


const useStyles = makeStyles(theme => ({
  footer: {
    minHeight: 300,
    background: '#00ae9c',
    marginTop: 50,
  },
  floatingWhatsapp: {
    bottom: '20px',
    right: '20px',
    left: 'auto',
    position: 'absolute',
    zIndex: 1000,
    border: 0,
    width: '60px',
    height: '60px',
  },
  logo: {
    maxWidth: 200,
    maxHeight: 200,
    marginBottom: 20
  },
  iconList: {
    display: 'flex',
    position: 'relative',
    color: '#fff',
    borderTop: '1px #fff solid',
    borderBottom: '1px #fff solid',
    padding: 5,
    paddingLeft: 21,
    justifyContent: 'center',
    alignItems: 'center',
  },
  labelSocial: {
    position: 'relative',
    margin: 5,
  },
  colorIcon: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
  },
  textFooter: {
    color: '#fff',
    fontWeight: 250,
    fontSize: 15,
  },
  horario: {
    fontWeight: 250,
    fontSize: 15,
    color: '#fff',
  },
  textHorario: {
    textAlign: 'center',
  },
  pl116: {
    paddingLeft: 116,
  },
}))

export default function Footer() {
  const styles = useStyles()
  return (
    <footer className={styles.footer}>
      <Link target="_blank" to="https://wa.me/+5579996855993" className={styles.floatingWhatsapp}>
        <img src={logo} className={styles.floatingWhatsapp} alt="Whatsapp" id="whatsapp-btn" />
      </Link>
      <div style={{ paddingTop: 40, paddingLeft: 20 }}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item xs={12} md={12} sm>
            <img src={logo} className={styles.logo} alt="logo" />
          </Grid>
          <Grid container justify="center" alignItems="center"></Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid
            item
            xs={9}
            sm={7}
            md={4}
            lg={4}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Box
              className={styles.iconList}
              alignItems="center"
              justify="center"
            >
              <a
                href="http://facebook.com/facjardins"
                className={styles.colorIcon}
                target="_blank"
              >
                <FacebookIcon fontSize="large" />
                <span className={styles.labelSocial}>Facebook</span>
              </a>
              <a
                href="https://www.instagram.com/unijardins/"
                className={styles.colorIcon}
                target="_blank"
              >
                <InstagramIcon fontSize="large" />
                <span className={styles.labelSocial}>Instagram</span>
              </a>
              <a
                href="https://www.youtube.com/channel/UCsGxry1VPtzeSLPnVdTz6NQ"
                className={styles.colorIcon}
                target="_blank"
              >
                <YoutubeIcon fontSize="large" />
                <span className={styles.labelSocial}>Youtube</span>
              </a>
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid
            item
            xs={9}
            sm={7}
            md={5}
            lg={4}
            justify="center"
            alignItems="center"
            className={styles.textHorario}
          >
            <Typography>
              <span className={styles.horario}>
                Praça Nossa Sra. Aparecida, 40 - Cidade Nova, Lagarto - SE, 49400-000 (Antiga Faculdade Dom Pedro de Sergipe)
              </span>
            </Typography>
            <Typography>
              <span className={styles.horario}>
                As futuras instalações:  no Shopping Centro SUL - Lagarto
              </span>
            </Typography>
            <hr style={{ backgroundColor: "#FFFFFF"}} />
            <Typography>
              <span className={styles.horario}>
                Rua Mestre Messias de Santana, Conjunto Orlando Dantas, nº 293, Bairro São Conrado - Aracaju/Sergipe - CEP 49042-790
              </span>
            </Typography>
            <Typography className={styles.textFooter}>
              Horário de funcionamento:
            </Typography>
            <Typography>
              <span className={styles.horario}>
                segunda a sexta de 8h às 22h - Sábado de 8h as 12h
              </span>
            </Typography>

            <Typography>
              <span className={styles.horario}>
                <a href="tel:08000068002" className={styles.horario}>
                  {'  '}
                  0800 006 8002
                </a>
                {'  '}|{'  '}
                <a href="tel:7930220892" className={styles.horario}>
                  79 3022-0892
                </a>
                {'  '}|{'  '}
                <a
                  href="https://api.whatsapp.com/send?phone=5579996855993"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.horario}
                >
                  79 99685-5993 <WhatsAppIcon fontSize="small" />
                </a>
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid
            item
            xs={9}
            sm={7}
            md={5}
            lg={4}
            justify="center"
            alignItems="center"
            className={styles.textHorario}
          >
            <div>

            </div>
          </Grid>
        </Grid>
      </div>


    </footer>
  )
}
