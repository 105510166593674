import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: 0;
}
body,
html {
  font-family: 'Roboto', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  width: 100%;
  overflow-x: hidden !important
}
a {
  text-decoration: none !important;
  color: #000;
}
`

export default GlobalStyle
