import styled from 'styled-components'
import { Drawer } from '@material-ui/core'

export const StyledDrawer = styled(Drawer)`
  svg {
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 15px;
  }

  & .MuiDrawer-paper {
    width: 250px;
  }

  & .MuiDivider-root {
    margin-top: 60px;
  }
`
