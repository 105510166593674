import { Box, Button } from '@material-ui/core'
import { OutboundLink } from 'gatsby-plugin-gtag'
import styled from 'styled-components'

export const Div = styled(Box)`
  background: #00ae9c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  height: 130px;
  button {
    background: #ef4e45;
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
  }
  .menu-wrapper button:hover {
    background: #ffc609;
  }
  a {
    margin: 0 10px;
    color: #fff;
  }
  button,
  a {
    text-transform: initial;
    font-size: 16px;
  }
  .menu-button {
    display: none;
  }
  .menu-wrapper {
    display: flex;
    align-items: center;
  }
  .menu-wrapper a {
    background: #ef4e45;
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
    padding: 9px;
    border-radius: 4px;
  }
  @media (max-width: 870px) {
    .menu-button {
      display: initial;
    }
      .logo-all-header {
        display: none;
      }
    .menu-wrapper {
      display: none;
    }
  }
  @media (max-width: 968px) {
    .action-button {
      display: none;
    }
  }
`

export const LogoWrapper = styled.div`
  & img {
    max-width: 180px;
  }
`

export const ActionButton = styled(OutboundLink)`
  background: #ef4e45;
  border-radius: 20px;
  border: 2px solid #ef4e45;
  padding: 7px 20px;
  transition: 0.5s;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
  cursor: pointer;
  color: #fff;
  &:hover {
    background: #ffc609;
    border-color: #ffc609;
    color: #fff;
  }
`
