import React from 'react'
import { Link } from 'gatsby'
import { Button, Input } from '@material-ui/core'

import PublicIcon from '@material-ui/icons/Public'
import {
  WhatsApp as WhatsAppIcon,
  Phone as PhoneIcon,
  Business as BusinessIcon
} from '@material-ui/icons'

import { makeStyles } from '@material-ui/styles/'
import { Div } from './styles'

const useStyles = makeStyles(theme => ({
  customInput: {
    padding: 6,
    background: '#FFFFFF',
    borderRadius: 5,
  },
  button: {
    margin: '0 10px',
    padding: 11,
  },
  customBtn: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    backgroundColor: '#00ae9c',
    fontSize: '16px !important',
    margin: '0 10px',
    padding: '8px 25px',
    '&:hover': {
      backgroundColor: '#4caf50',
      boxShadow: 'none',
    },
  },
  iconSpacing: {
    margin: '0 10px',
  },
}))
export default function PreHeader() {
  const classes = useStyles()

  return (
    <Div component="header">
      <div className="contact">
        <a
          href="tel:08000068002"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <PhoneIcon fontSize="small" className={classes.iconSpacing} />
          0800 006 8002
        </a>
        <span style={{ color: '#fff' }}>|</span>
        <a
          href="tel:7930220892"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <PhoneIcon fontSize="small" className={classes.iconSpacing} />
          79 3022-0892
        </a>
        <span style={{ color: '#fff' }}>|</span>
        <a
          href="https://api.whatsapp.com/send?phone=5579996855993"
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <WhatsAppIcon fontSize="small" className={classes.iconSpacing} />
          79 99685-5993{' '}
        </a>
        <span style={{ color: '#fff' }}>|</span>
      </div>
      <form method="post" action="https://www.ejardins.com.br/login/index.php" target="_blank">
        <h3 style={{ color: '#fff', fontSize: 17, margin: '10px 20px' }}>
          Acesso ao AVA
        </h3>
        <Input
          placeholder="CPF"
          className={classes.customInput}
          color="secondary"
          inputProps={{ 'aria-label': 'description', name: 'username' }}
        />
        <Input
          placeholder="Senha"
          type="password"
          className={classes.customInput}
          color="secondary"
          style={{ marginLeft: 15 }}
          inputProps={{ 'aria-label': 'description', name: 'password' }}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.customBtn}
          type="submit"
        >
          Entrar
        </Button>
      </form>
    </Div>
  )
}