import styled from 'styled-components'
import { Collapse } from '@material-ui/core'

export const StyledCollapse = styled(Collapse)`
  & .MuiList-padding {
    padding-left: 25px;
  }
  & .MuiListItemText-primary {
    font-size: 14px;
  }
`
